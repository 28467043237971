import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/gloable.css'
import request from "@/utils/request";

Vue.config.productionTip = false

Vue.use(ElementUI, { size: "mini" });

Vue.prototype.request=request

Vue.prototype.path = "https://ai.pipayshop.com"
Vue.prototype.piPath = "https://sea.im/api"

// Vue.prototype.path = "http://localhost:9999"
// Vue.prototype.piPath = "http://localhost:9999"

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
